@import "src/assets/styles/common";

.slide-component {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    display: flex;

    &.birthday {
        background-image: url("./../../../assets/images/birth-day-background.jpg");
    }

    &.new-employee {
        background-image: url("./../../../assets/images/new-employee-background.jpg");
    }

    &.video {
        video {
            object-fit: fill;
        }
    }

    .slide-meta {
        position: absolute;
        bottom: 0;
        padding: 30px;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        color: $white;

        .slide-title {
            font-size: 32px;
            font-weight: $font-weight-bold;
        }

        .slide-description {
            font-size: 24px;
            font-family: $font-family-4;
        }
    }

    .birthday-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        margin-top: 8%;

        .birthday-profile-image {
            width: 200px;
            height: 200px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .birthday-title {
            font-size: 32px;
            margin-top: 20px;
        }

        .birthday-subtitle {
            font-family: $font-family-4;
            font-size: 20px;
        }

        .birthday-wishes {
            margin-top: 10px;
            font-family: $font-family-3;
            font-size: 28px;
            max-width: 60%;
            text-align: center;
        }
    }

    .new-employee-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        margin-top: 8%;

        .new-employee-profile-image {
            width: 200px;
            height: 200px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .new-employee-title {
            font-size: 32px;
            margin-top: 20px;
        }

        .new-employee-subtitle {
            font-family: $font-family-4;
            font-size: 20px;
        }

        .new-employee-wishes {
            margin-top: 10px;
            font-family: $font-family-3;
            font-size: 28px;
            max-width: 60%;
            text-align: center;
        }
    }
}
