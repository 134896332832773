@import "animations";
@import "variables";
@import 'react-toastify/dist/ReactToastify.css';
@import "fonts";

@import "helpers";
@import "mixins";
@import "responsive-mixins";
@import "responsive-display";
@import "normalize";
@import "slide-animation";

.app-version {
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
}

.app-layout {
  display: flex;
}

.h-v-center {
  @include display-center();
}

.clear-fix {
  clear: both;
}
