.slide-top {
  position: relative;
  animation: animatetop 2s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

.slide-left {
  position: relative;
  animation: animateleft 2s;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0
  }
  to {
    left: 0;
    opacity: 1
  }
}

.slide-right {
  position: relative;
  animation: animateright 2s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0
  }
  to {
    right: 0;
    opacity: 1
  }
}

.slide-bottom {
  position: relative;
  animation: animatebottom 2s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

.slide-zoom {
  animation: animatezoom 0.6s
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}
