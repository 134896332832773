.blink {
  animation: blinker 700ms linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Safari */
@-webkit-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.glass-effect {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.1em;
  height: 100%;
  width:  100%;
  z-index: 10;
}


