@import "mixins";

$primary-color: #000080;
$white: #ffffff;
$black: #000000;

$font-family: 'Roboto';
$font-family-2: 'Sevillana';
$font-family-3: 'Dancing Script', cursive;
$font-family-4: 'Martel', serif;

$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$body-background-color: $primary-color;
$body-color: #212B36;
$text-light-1: #ABB6BE;
$text-light-2: #6D6D6D;
